// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./change_password.scss"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <section id="change_password" class="flex-column">
    
    <div id="miniheader" class="flex-row miniheader">
      <img
        id="thumbnail"
        click.delegate="router.navigateBack()"
        src="${___HTML_LOADER_IMPORT_0___}"
      />
      <div class="flex-grow"></div>
      <h1 t="auth.change_password.title"></h1>
      <div class="flex-grow"></div>
    </div>
    
    <form id="change_passwordForm" class="inputs column" submit.delegate="change_password()"
      class.bind="shaking ? 'shake' : ''">
      <div>
        <input id="oldPassword" ref="inputPsw" type="password" value.bind="oldPassword"
          i18n="[placeholder]auth.change_password.change_password2" focus.trigger="focusHide=true" blur.trigger="focusHide=false" />
        <i click.delegate="showPassword()" if.bind="inputPsw.type === 'text'" class="fas fa-eye eye"></i>
        <i click.delegate="showPassword()" if.bind="inputPsw.type === 'password'" class="fas fa-eye-slash eye"></i>
      </div>
      <div>
        <input id="newPassword" ref="inputPsw2" type="password" value.bind="newPassword"
          i18n="[placeholder]auth.change_password.change_password3" focus.trigger="focusHide=true" blur.trigger="focusHide=false" />
      </div>
      <div>
        <input id="confirmedPassword" ref="inputPsw3" type="password" value.bind="newPassword_verification"
          i18n="[placeholder]auth.change_password.change_password6" focus.trigger="focusHide=true" blur.trigger="focusHide=false" />
      </div>
    </form>
    <div class="spacer-16"></div>
    <div id="forgotpassword" class="flex-column">
      <span class="gray" i18n="auth.change_password.change_password4">Mot de passe oublié ?</span>
      <a click.delegate='toForgot()' i18n="auth.change_password.change_password5">
      </a>
    </div>
    <div class='error flex-column' if.bind='displayError'>
      <span>
        \${errorMessage}
      </span>
    </div>
    <div class="spacer-32"></div>
    </div>
    <button type="submit" form="change_passwordForm" class="btn btn-primary" click.delegate="changePassword()"
      disabled.bind="isLoading" if.bind="oldPassword && newPassword && newPassword_verification"
      id="confirmchange_passwordButton">
      <span if.bind="!isLoading" i18n="auth.validate"></span>
      <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
    </button>
    <button class="btn btn-primary fakeDisabled" click.delegate="shakeError()"
      if.bind="!oldPassword || !newPassword || !newPassword_verification">
      <span i18n="auth.validate"></span>
    </button>
  </section>
</template>
`;
// Exports
export default code;
// Module
var code = `<template>
  <require from="./confirmed.scss"></require>
  <require from="../../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="change_password_confirmed" class="flex-column">
    <div id="header">
      <h1 i18n="auth.change_password.change_password_confirmed1"></h1>
    </div>
    <div class="flex-grow"></div>
    <animated-checkmark></animated-checkmark>
    <div class="flex-grow"></div>
    <p i18n="auth.change_password.change_password_confirmed2"></p>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" i18n="home.goBackHome" click.delegate="goToHome()"></button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;
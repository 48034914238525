import { Router } from 'aurelia-router';
import { UsersHttpClients } from "../../../http_clients/UsersHttpClients";
import { AuthService } from 'aurelia-auth';
import { autoinject } from 'aurelia-framework';
import { json } from 'aurelia-fetch-client';
import { I18N } from "aurelia-i18n";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
@connectTo()
@autoinject

export class Changepsw {
  private oldPassword: String;
  private newPassword: String;
  private newPassword_verification: String;
  private is_error: boolean = false;
  private me: any;
  private displayError = false;
  private inputPsw: HTMLInputElement;
  private inputPsw2: HTMLInputElement;
  private inputPsw3: HTMLInputElement;
  private errorMessage;
  private isLoading: boolean = false;
  private shaking: boolean;
  
  constructor(
    private authService: AuthService,
    private usersHttpClients: UsersHttpClients,
    private router: Router,
    private i18n: I18N) {
    this.authService = authService;
  
  }

  attached(){
    this.getMe();
  }

  async getMe() {
    this.me = await this.authService.getMe();
  }

  changePassword() {
    this.isLoading = true;
    if (this.validateNewPassword() == true) {
      var that = this;
      this.usersHttpClients.fetch('/profile/changePassword', {
        method: 'POST',
        body: json({
          "oldPassword": this.oldPassword,
          "newPassword": this.newPassword,
        })
      }).then(response => {
        if (response.ok) { // response.status == 200
          that.router.navigateToRoute('change_password_confirmed');
        }
        else { // response.status == 409,422...
          this.isLoading = false;
          this.displayError = false;
          this.errorMessage = "";
          response.json()
            .then(json_response => {
              if (json_response.errors.msg == "WRONG_PASSWORD") {
                this.displayError = true;
                this.errorMessage = this.i18n.tr("auth.change_password.change_passwordts1");
                this.shakeError()
              }
              if (json_response.errors.msg[0].msg == "PASSWORD_TOO_SHORT_MIN_5") {
                this.displayError = true;
                this.errorMessage = this.i18n.tr("auth.change_password.change_passwordts2");
                this.shakeError()
              }
              if (json_response.errors.msg[0].msg == "IS_MISSING") {
                this.displayError = true;
                this.errorMessage = this.i18n.tr("auth.change_password.change_passwordts3");
                this.shakeError()
              }
            })
        }
      })
    }
    else {
      this.errorMessage = this.i18n.tr("auth.change_password.change_passwordts4");
      this.displayError = true
      this.isLoading = false;
    }
  }
  validateNewPassword() {
    if (this.newPassword == this.newPassword_verification)
      return true;
    else return false;
  }
  async shakeError() {
    this.shaking = true;
    setTimeout(() => { 
      this.shaking = false;
    }, 200);
  }

  showPassword() {
    this.inputPsw.type === "password" ? this.inputPsw.type = "text" : this.inputPsw.type = "password"
    this.inputPsw2.type === "password" ? this.inputPsw2.type = "text" : this.inputPsw2.type = "password"
    this.inputPsw3.type === "password" ? this.inputPsw3.type = "text" : this.inputPsw3.type = "password"

  }

  toForgot() {
    this.router.navigateToRoute('forgot')
  }


}

import { Router } from 'aurelia-router';
import { UsersHttpClients } from "../../../../http_clients/UsersHttpClients";
import { AuthService } from 'aurelia-auth';
import { autoinject } from 'aurelia-framework';
import { json } from 'aurelia-fetch-client';

@autoinject 

export class changePasswordConfirmed {

  constructor(private authService: AuthService,
    private usersHttpClients: UsersHttpClients,
    private router: Router) { }  
  goToHome(){
    this.router.navigateToRoute('home')
  }
}
